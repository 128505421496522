.desktop-sidebar {
    background: $menu-background-gradient;
}

body:not(.show-menu) {
    .desktop-menu {
        display: none;
    }
}

.back-icon {
    svg {
        position: absolute;
        top: 33px;
        fill: $base-blue-100;
    }
}

.MuiList-padding {
    padding-top: 0px !important;
}

.menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 300px;

    .menu-header {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
    }

    .MuiListItem-gutters {
        padding: 11px 24px;
    }

    .active {
        .MuiListItem-gutters {
            padding: 3px 12px;
        }

        .MuiListItemText-root {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            padding: 8px 12px;
        }
    }

    .link-list-item {
        text-decoration: none;
        display: flex;

        .MuiTypography-body1, a {
            font-size: 20px;
            line-height: 25px;
            font-family: $font-aalto-semibold;
            color: $white;
        }
    }

    a {
        text-decoration: none;
    }

    .logout-list-item {
        text-decoration: none;

        .MuiTypography-body1 {
            font-size: 20px;
            line-height: 25px;
            color: $white;
        }

        svg {
            padding-right: 10px;
            fill: $white;
        }
    }

    .green-button {
        width: 90%;
    }

    .menu-logo {
        padding: 24px;
        width: 94px;
    }

    .close-button-nav {
        color: white;
        justify-content: flex-end;
        padding-right: 24px;
    }

    .logout-button {
        padding-left: 4px;
        padding-top: 40px;

        .MuiTypography-body1 {
            font-family: $font-aalto-semibold;
        }

        .logout-icon {
            padding-right: 20px;
        }
    }
}

.sidebar-btn {
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: $tablet-breakpoint + 1) {
    body {
        overflow: inherit !important;

        .menu {
            .close-button-nav {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    body:not(.show-mobile-menu) {
        .desktop-menu {
            display: none;
        }
    }

    .menu {
        min-height: 100vh;

        .logout-button {
            padding-bottom: 20px;
        }

        .menu-header {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
            background: none;
        }

        .link-list-item {
            .MuiListItem-root {
                border-bottom: 1px solid rgba(164, 162, 162, 0.2);
                margin: 0 10px;
            }
        }
    }

    .desktop-sidebar {
        position: fixed;
        z-index: 200;
    }

    .active {
        .MuiListItemText-root {
            background: transparent !important;
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .menu {
        .MuiTypography-body1, a {
            font-size: 17px !important;
            line-height: 20px !important;
        }

        .sidebar-btn {
            .MuiButton-label {
                font-size: 17px;
                line-height: 22px;
            }
        }
    }
}

@media only screen and (max-width: $breakpoint-xs) {
    .desktop-menu {
        display: flex;
        justify-content: space-evenly;
        height: 100vh;
        width: 100vw;
    }

    .menu {
        width: 100vw;

        .link-list-item {
            .MuiListItem-root {
                margin: 0 20px;
            }

            .MuiListItem-gutters {
                padding: 11px 12px;
            }
        }

        .active {
            .MuiListItem-gutters {
                padding: 3px 0;
            }

            .MuiListItemText-root {
                background: rgba(255, 255, 255, 0.1);
                border-radius: 4px;
                padding: 8px 12px;
            }
        }
    }
}
