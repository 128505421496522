#user_dashboard_root {
    .Login {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .step-container.container-blue {
            padding: 50px 45px 85px 45px;
            max-width: 640px;
            width: 100%;
            box-sizing: border-box;

            .login-img {
                display: flex;
                justify-content: center;
                padding: 145px 0;
            }

            .lost-password-btn-container {
                padding-top: 20px;
                margin-top: 15px;

                button {
                    padding: 0;
                    color: white;
                    text-decoration: none;
                    border-bottom: 2px solid $confirmation-green;
                    border-radius: 0;
                    text-transform: none;
                    font-size: 20px;
                    line-height: 25px;
                    font-family: inherit;
                }
            }
        }

        .asv-text-input-container {
            max-width: 400px;
            margin: 0 auto;
        }
    }

    .auth-action-form-container {

        .screen-content {
            box-sizing: border-box;
            max-width: 640px;
            margin: auto;
            padding: 40px;

            .centered-message-container {
                padding:48px 16px 0 16px;
                text-align: center;
            }

            p {
                text-align: left;
            }

            .success-message {
                text-align: center;
                margin-top: 48px;
            }

            .input-container {
                width: 100%;
                padding-top: 40px;

                .MuiInputBase-root, p {
                    font-size: 20px;
                }
            }
        }
    }

}

@media only screen and (max-width: 1500px) {
    .Login {
        .login-img {
            padding: 100px 0 !important;
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .step-container.container-blue {
        margin: 0 !important;
        border-radius: 0;
        padding: 0 !important;
        width: 100% !important;
        min-height: 100vh;

        > *:not(.box-size-container) {
            padding: 0 24px;
        }

        .asv-text-input-container {
            //width: 70% !important;
        }

        .asv-text-input-container .MuiInputBase-root {
            height: 40px;
        }

        button {
            max-width: 70%;
        }

        .lost-password-btn-container {
            font-size: 16px !important;
        }
    }


        .screen-content {
            padding: 40px 20px !important;
            width: 100% !important;
            min-height: 100vh;

            .MuiInputBase-root, p {
                font-size: 18px !important;
            }
        }



}
