.box-access-configuration-container {
    .current-user-action-container ._for-current-user,
    .current-user-action-container ._title,
    .other-user-confirmation-container ._for-other-user,
    .other-user-confirmation-container ._title {
        margin: 0;
        font-weight: bold;
        color: $base-blue-100;
    }

    .current-user-action-container ._for-current-user,
    .other-user-confirmation-container ._for-other-user {
        text-transform: uppercase;
        color: $base-blue-300;
        font-size: 12px;
    }

    .current-user-action-container ._content,
    .other-user-confirmation-container ._content {
        color: $base-blue-200;
        margin-bottom: 0;
    }

    .current-user-action-container,
    .other-user-confirmation-container {
        ._header-section {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .checked-step {
                color: $highlight-primary;
            }
        }
    }

    .current-user-action-container {
        border: 1px solid $base-blue-400;
        padding: 15px;
        text-align: left;
        margin-bottom: 15px;
        background-color: white;
    }

    .other-user-confirmation-container {
        padding: 15px;
        background-color: $beige;
        margin-bottom: 15px;
        text-align: left;

        p {
            color: black;
        }
    }

    ._description {
        text-align: left;
        padding-bottom: 20px;
    }
}

.current-user-action-container .proceed-granting-access,
.confirm-booking-access-container .confirm-booking-access-button,
.finish-granting-access-container .finish-granting-access-button {
    margin-top: 15px;
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    padding: 15px 0;
}

.access-code-container, ._partner-container {
    display: flex;
    justify-content: space-between;
    background-color: $beige;
    padding: 15px 10px;
    color: black;
    margin-top: 15px;
}

.access-code-container .copy-code {
    color: $link-blue;

    &:active {
        color: $base-blue-100;
    }
}

._partner-container {
    align-items: center;
    text-align: left;

    ._partner-info {
        color: $base-blue-100;
        display: flex;
        flex-direction: column;

        ._name {
            font-weight: bold;
        }
    }

    .checked-step {
        color: $highlight-primary;
    }
}

.configure-shared-access-container {
    .add-user-img {
        padding: 150px 0;
    }

    ._title {
        padding-top: 40px;
    }
}

.confirm-booking-access-container {
    ._congratulations-text {
        text-align: left;
        padding-bottom: 20px;
    }

    .payment {
        text-align: left;
        padding-top: 40px;

        .monthly-payment {
            display: flex;
            justify-content: space-between;

            p {
                font-family: $font-aalto-medium;
            }
        }
    }
}

.finish-granting-access-container {

    text-align: center;

    .blue-button {
        margin-top: 40px;
        max-width: 520px;
    }

    .access-activated-text {
        padding: 20px 0;
    }

    .access-activated-row {
        display: flex;
        align-items: center;
        max-width: 520px;
        margin: auto;

        img {
            padding-right: 20px;
            width: 40px;
            height: 40px;
        }

        p {
            text-align: left;
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .configure-shared-access-container {
        padding-bottom: 40px;

        .grant-access-container {
            padding: 0 10px !important;

            ._title {
                padding-top: 20px;
            }
        }

        .add-user-img {
            padding: 75px 0;
        }

        .overlay {
            padding: 0 !important;
        }

        .container-blue {
            padding: 0 !important;
            border-radius: 0;
        }
    }
}
