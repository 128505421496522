.request-tan {
  .title, .description, .subtitle {
      text-align: left;
  }

  .title {
      width: 100%;
      margin-top: 40px;
      padding-left: 0px;
      font-family: $font-aalto-semibold;
  }

  .subtitle {
    font-size: 16px;
    font-family: $font-aalto-medium;
  }

  .tan-container {
    margin-top: 40px;
    position: relative;

    .tan-container-background {
      width: 100%;
      height: 120px;
    }
    .tan-field-container {
      display: flex;
      position: absolute;
      top: 24px;
      bottom: 24px;
      left: 24px;
      right: 24px;
      background-color: #F7F1EF;
      justify-content: center;
      align-items: center;
    }
    .tan-field {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .tan-field-label {
        position: absolute;
        left: 16px;
      }

      .tan-field-value {
        text-align: center;
        font-size: 40px;
        font-family: $font-aalto-semibold;
      }
    }
  }
}
