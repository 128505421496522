.change-pin {
  .title, .description {
      text-align: left;
  }

  .title {
      padding-left: 0px;
      font-family: $font-aalto-semibold;
  }
}
