$background-blue: #091541;
$background-gradient: linear-gradient(180deg, #091541, #091541);//linear-gradient(19.29deg, #091541 -42.44%, #1F97E2 190.48%);
$menu-background-gradient: linear-gradient(180deg, #091541, #091541);//linear-gradient(180deg, #30508D 0%, #233460 50.52%, #091541 100%);
$header-gradient: linear-gradient(180deg, #091541, #091541);//linear-gradient(196.51deg, #1F97E2 -307.68%, #216CAA -131.87%, #224A7D 6.1%, #233360 97.8%);
$white: #fff;
$link-blue: #1f97e2;
$confirmation-green: #1ee2c8;
$confirmation-green-dark: #1BC9B4;
$input-grey: #A4A2A2;
$black: #000;
$pink: #FF8E91;
$warning-dark: #66393A;
$red: #EA4335;
$progress-light-green: #A9ECE3;
$beige: #F7F1EF;
$base-blue-500: #F4F7FD;
$base_blue_450: #E4E6F0;
$base-blue-400: #CED0D9;
$base-blue-350: #9FA6BF;
$base-blue-300: #626A87;
$base-blue-200: #23315E;
$base-blue-100: #091541;
$highlight-primary: #1EE2CA;
$alert-orange: #FFCD00;
$border-radius: 4px;

$breakpoint: 992px;
$breakpoint-xs: 376px;
$breakpoint-xs-interim: 340px;
$upper-breakpoint: 1300px;
$form-horizontal-padding: 25px;
$form-horizontal-extra-padding: 12px;
$form-max-width: calc(#{$breakpoint-xs - (2 * $form-horizontal-padding)});
$box-padding: 10px;
$tablet-breakpoint: 768px;
$mobile-breakpoint: 480px;

$input-field-label-offset: 23px;
$input-field-label-offset-desktop: 16px;
$input-field-height: 48px;

@font-face {
    font-family: 'Aalto Regular';
    src: url('fonts/Regular.woff2') format('woff2'),
    url('fonts/Regular.woff') format('woff');
}

@font-face {
    font-family: 'Aalto Medium';
    src: url('fonts/Medium.woff') format('woff');
}

@font-face {
    font-family: 'Aalto SemiBold';
    src: url('fonts/SemiBold.woff2') format('woff2');
}

@font-face {
    font-family: 'Aalto Bold';
    src: url('fonts/Bold.woff2') format('woff2'),
    url('fonts/Bold.woff') format('woff');
}

$font-aalto-regular: 'Aalto Regular', sans-serif;
$font-aalto-medium: 'Aalto Medium', sans-serif;
$font-aalto-bold: 'Aalto Bold', sans-serif;
$font-aalto-semibold: 'Aalto Semibold', sans-serif;
