@import 'styles/constants';

.boxes-overview-container {
    position: relative;
    padding-bottom: 40px;
    text-align: center;

    .box-details {
        display: flex;
        align-items: center;
    }

    .padded-container {
        max-width: 100%;
        padding: 0 100px;
    }

    .page-header {
        .MuiButton-text {
            padding: 0 12px;
        }
    }

    ._cancelled {
        display: inline-block;
        border-radius: $border-radius;
        padding: 4px 8px;
        background: $pink;
        color: $black;
        font-family: $font-aalto-medium;
        margin-top: 8px;
        width: fit-content;
    }

    .shared-access {
        margin-top: 8px;
    }

    .box-row {
        display: flex;
        padding: 24px;
        background-color: $base-blue-500;
        margin-bottom: 16px;

        .box-image {
            margin-right: 30px;
            width: 100px;
            height: auto;

            &._disable {
                opacity: 0.3;
            }
        }

        ._info-badge {
            margin: 4px 0 0 0;
            padding: 3px 8px;
            border-radius: $border-radius;
            color: $background-blue;
            background: $beige;
        }

        ._warning-badge {
            margin: 4px 0 0 0;
            padding: 2px 8px;
            border-radius: $border-radius;
            color: $background-blue;
            background: $pink;
        }

        .box-info {
            display: flex;
            flex-direction: column;
            text-align: left;
            align-self: center;

            p {
                &:first-of-type {
                    font-family: $font-aalto-semibold;
                    font-size: 20px;
                    line-height: 25px;
                    color: $base-blue-100;
                }

                font-family: $font-aalto-regular;
                margin: 0;
                font-size: 17px;
                line-height: 21px;
                color: $input-grey;
            }
        }
    }

    .blue-button {
        margin-bottom: 26px;
    }

    .request-access {
        font-size: 20px;
        line-height: 25px;
        font-family: $font-aalto-semibold;
    }


    .add-icon {
        svg {
            width: 24px;
            height: 24px;
        }
    }

    .empty-box-list {
        padding-top: 50px;

        .box-image {
            padding: 40px 0;
        }

        .title {
            font-size: 20px;
            line-height: 25px;
            font-family: $font-aalto-semibold;
        }

        .subtitle {
            font-size: 18px;
            color: $base-blue-300;
            font-family: $font-aalto-medium;
        }
    }
}

@media only screen and (min-width: $breakpoint + 1) {
    .boxes-overview-container {
        .notification {
            margin: 0 100px 24px 100px;
        }
    }
}

@media only screen and (min-width: $tablet-breakpoint + 1) and (max-width: $breakpoint) {
    .boxes-overview-container {
        .notification {
            margin: 0 40px 24px 40px;
        }

        .box-details {
            padding-right: 10px;
        }
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    .boxes-overview-container {
        .notification {
            margin: 0;
        }

        .box-list-header {
            display: flex;
            justify-content: space-between;
        }

        .placeholder-close-button {
            display: none;
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .boxes-overview-container {
        .box-row {
            margin-bottom: 0;
            padding: 20px 10px;
            background:transparent;

            &:not(:last-child) {
                border-bottom: 1px solid $base-blue-400;
            }

            .box-details {
                justify-content: space-between;
                align-items: center;
            }

            .shared-access {
                display: flex;
                align-items: center;

                .user-box {
                    padding: 5px 6px;
                    font-size: 15px;
                }
            }
        }

        .blue-button {
            max-width: 85%;
        }

        .page-header {
            padding: 9px 0 !important;
        }

        .empty-box-list {
            padding-top: 0;

            .box-image {
                padding: 20px 0;
                width: 150px;
                height: auto;
            }

            p {
                margin: 0;
            }

            .title {
                font-size: 18px;
            }

            .subtitle {
                font-size: 16px;
            }
        }

        .request-access {
            font-size: 18px;
        }
    }
}

@media only screen and (max-width: $breakpoint-xs-interim) {
    .boxes-overview-container {
        .padded-container {
            padding: 20px 10px !important;
        }

        .box-row .box-image {
            margin-right: 15px;
            width: 80px;
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {

        .boxes-overview-container {
            .padded-container {
                padding: 0px 20px;
            }
        }

}
