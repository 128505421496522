@import 'styles/constants';

.access-request-container.status {
    .box-container {
        border: 1px solid rgba($input-grey, .5);
        border-radius: 4px;
        padding: 15px;

        .box-info {
            text-align: left;
            padding-top: 16px;

            span {
                font-weight: normal;
                font-family: $font-aalto-medium;
                font-size: 17px;
                line-height: 21px;
            }
        }
    }

    .text-success {
        margin-top: 0;
        padding-top: 25px !important;
        font-size: 17px;
        line-height: 21px;
        text-align: left;

        ._content {
            margin-bottom: 0;

            div:not(:last-of-type) {
                padding-bottom: 15px;
            }
        }

        button {
            height: 55px;
            margin-top: 25px;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
    }
}
