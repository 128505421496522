@import 'constants';

body {
    #root,
    #user_dashboard_root {
        display: flex;
        flex-direction:row;

        .image-container {
            .large-image {
                display: none;
            }
        }

        @media only screen and (min-width: $tablet-breakpoint) {
            flex-direction:row;
        }

        @media only screen and (min-width: $breakpoint) {

            .card-body {
                .image-container {
                    .small-image {
                        display: none !important;
                    }

                    .large-image {
                        display: block !important;
                    }
                }
            }

        }

        .back-btn-container {
            button {
                padding: 0;

                path {
                    fill: $white;
                }
            }
        }
    }

    #user_dashboard_root {
        .password-container {
            width: 100%;
        }

        .box-row {
            cursor: pointer;
        }
    }
}

.small-print {
    font-size: 13px;
    font-family: $font-aalto-regular;
}
