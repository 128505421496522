.main-customer-info {
    border-radius: 0;

    .line {
        padding-top: 0;
        padding-bottom: 20px;
        margin: 0;
    }

    .blue-button {
        margin: 20px auto;
    }

    .container-blue {
        border-radius: 0;

        img {
            padding: 100px 0;
        }
    }
}

.main-customer-info-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #f2f2f2;
    text-align: start;
    padding: 20px 40px 70px 40px;
}

.main-customers-content-title span {
    font-family: $font-aalto-semibold;
}

.main-customers-content-text {
    padding: 20px 0;
}

@media only screen and (max-width: $mobile-breakpoint) {
    .main-customer-info {
        .overlay {
            padding: 0 !important;
        }

        .container-blue {
            padding: 0 !important;
            border-radius: 0;
        }

        .main-customer-info-content {
            padding: 20px;
        }
    }
}
