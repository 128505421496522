.customer-service-container {
    #customer-service-text {
        padding-bottom: 40px;
        text-align: left;
    }

    .customer-service-input {
        width: 100%;
        padding-bottom: 12px;
        margin-top: 24px;
    }

    #answer-input {
        color: $base-blue-100;
    }
}

@media only screen and (min-width: $breakpoint) {
    .customer-service-container {
        .page-header {
            justify-content: center;
        }
    }
}
