@import 'styles/constants';
@import 'styles/common';
@import 'concerns/boxes/concerns/boxes-overview/boxes-overview';
@import 'concerns/boxes/concerns/single-box-details/single-box-details';
@import 'concerns/boxes/concerns/single-box-request-status/single-box-request-status';
@import 'concerns/user-data/user-data';
@import 'concerns/request-tan/request-tan';
@import 'concerns/change-pin/change-pin';
@import 'concerns/authentication/login';
@import 'concerns/boxes/concerns/grant-access-to-box/configure-shared-access';
@import 'concerns/boxes/concerns/grant-access-to-box/box-access-configuration';
@import 'concerns/customer-service/customer-service';
@import 'concerns/report-loss/report-loss';
@import 'concerns/sidebar-navigation/sidebar-nav';
@import 'concerns/boxes/concerns/plan-visit/plan-visit.scss';
@import 'concerns/boxes/concerns/request-shared-access/request-access';
@import 'concerns/boxes/concerns/main-customer-information/main-customer-information';
@import 'concerns/boxes/concerns/main-customer-information/main-customer-information';
@import 'components/components';

body {

    #user_dashboard_root {
        .next-btn-container {
            text-align: center;
            margin-top: 20px;

            button {
                max-width:400px;
            }
        }

        .user-dashboard-main-container {
            .sidebar-drawer-container {
                position: absolute;
                top: 30px;
                left: 10px;

                .MuiSvgIcon-root {
                    color: white;
                }
            }

            .asv-form-container {
                max-width: 100%;
                padding: 0;
                margin: 0;
            }
        }
    }

    .padded-container {
        max-width: 640px;
        margin: auto;
    }

    .container-blue {
        text-align: center;
    }

    .page-header {
        font-size: 26px;
        line-height: 33px;
        font-family: $font-aalto-semibold;
        text-align: left;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        background-color: #fafafa;
        padding: 0px 12px;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:24px;
        border-bottom: 1px solid #f4f4f4;
        min-height:80px;

        .MuiSvgIcon-root {
            fill: $base-blue-100;
        }

        .placeholder-close-button {
            opacity: 0;
            cursor: inherit;
            margin: 0 12px;
            width: 32px;
        }

        .MuiButton-text.mobile-icon {
            padding: 0 12px;
        }

        .MuiButton-root {
            min-width: 0;
        }

        &.scroll-border {
            border-bottom: 1px solid $base-blue-400;
        }
    }

    .overlay-header {
        background: $header-gradient;
        box-shadow: 0 0.5px 0 rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(20px);
        color: $white;
        margin-bottom: 32px;
        border-bottom: 1px solid rgba(255,255,255,.1);

        .MuiSvgIcon-root {
            fill: $white;
        }

        .logo-container {
            width:96px;
        }

        .empty-placeholder {
            width:16px;
        }
    }

    .blue-button, .green-button, .default-button, .outline-button {
        text-transform: none;
        margin-top: 40px;
        border-radius: 0;
        width: 100%;
        color: $white;
        height:56px;
        font-family: $font-aalto-semibold;
        font-size: 20px;

        .Mui-disabled {
            background-color: $input-grey;
        }
    }


    .text-button {
        font-size: 20px;
        color: $base-blue-100;
        padding-top: 50px;
        text-decoration-color: $highlight-primary;
        font-family: $font-aalto-semibold;
    }

    .MuiButton-contained.Mui-disabled {
        background-color: $input-grey;
        color: $white;
    }

    .default-button {
        color: $base-blue-100;

        &:hover {
            color: $base-blue-300;
        }
    }

    .blue-button {
        background-color: $base-blue-200;

        &:hover {
            background-color: $base-blue-300;
        }
    }

    .green-button {
        background-color: $highlight-primary;
        color: $base-blue-100 !important;

        &.Mui-disabled  {
            color: $white !important;
        }

        &:hover {
            background-color: $base-blue-500;
        }
    }

    .outline-button {
        margin-top: 20px;
        //padding: 25px 30px;
        display: inline-block;
        text-transform: none;
        border-radius: 0;
        max-width: 100%;
        color: $base-blue-100;
    }

    .MuiButton-outlined {
        border: 2px solid $base-blue-100;
    }

    .alert-message {
        display: flex;
        justify-content: center;
        border: 1px dashed $base-blue-400;
        border-radius: 5px;
        margin-top: 20px;
        padding: 20px 30px;
        text-align: left;

        p {
            align-self: center;
            margin: 0;
            padding-left: 20px;
            color: $base-blue-300;
        }
    }

    .hide {
        display: none;
    }

    .semi-bold {
        font-family: $font-aalto-semibold !important;
    }

    .semi-bold-arrow {
        font-family: $font-aalto-semibold !important;
        cursor: pointer;
    }

    .semi-bold-arrow:hover {
        font-family: $font-aalto-semibold !important;
        cursor: pointer;
        border-radius: 4px;
        padding: 4px 0;
        margin: -4px 0;
        background-color: $base-blue-300;
    }

    .bold {
        font-family: $font-aalto-bold !important;
    }

    .medium {
        font-family: $font-aalto-medium !important;
    }

    .container-blue {
        background: linear-gradient(180deg, #091541, #091541);//linear-gradient(180deg, #30508D 0%, #233460 50.52%, #091541 100%);
        border-radius: 5px;
        padding: 40px;
    }

    .shared-access {
        display: flex;
        align-items: flex-start;

        .user-box {
            display: flex;
            align-self: center;
            border-radius: 3px;
            padding: 4px 8px;
            font-size: 17px;
            line-height: 20px;
            cursor: pointer;

            &._white-text {
                color: $white;
                border: 1px solid #FFFFFF;
            }

            &._blue-text {
                border: 1px solid $base-blue-100;
            }

            &._background-warning {
                background: $pink;
            }

            &._background-highlight {
                background: $highlight-primary;
            }

            &._background-white {
                background: $white;
            }

            img {
                padding-right: 8px;
                width: 18px;
                height: 18px;
                align-self: center;
            }
        }
    }

    .overlay {
        width: 100%;
        max-width: 640px;
        margin: auto;
        padding: 25px;
        box-sizing: border-box;
        text-align: center;
    }

    [class*="SnackbarItem-root-"] {
        [class*="SnackbarItem-variantSuccess-"] {
            background-color: $confirmation-green;
        }

        [class*="SnackbarItem-variantError-"] {
            background-color: $pink;
        }

        #notistack-snackbar {
            color: $base-blue-100;
        }
    }
}

.notification {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    font-family: $font-aalto-medium;
    padding: 24px;
    font-size: 17px;
    line-height: 22px;

    &.user-authentication {
        background-color: $alert-orange;
    }

    &.card-register {
        background-color: $highlight-primary;
    }

    &.warning {
        background-color: $pink;
    }

    .MuiSvgIcon-root {
        width: 24px;
        height: 24px;
    }

    .notification-navigate {
        cursor: pointer;
    }
}

b, strong {
    font-weight: normal;
    font-family: $font-aalto-medium;
}

.configure-shared-access-container, .step5, .step7 {
    label {
        display: flex !important;
        justify-content: space-between !important;


        .MuiSwitch-thumb {
            color: $beige;
        }

        .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
            background-color: $highlight-primary;
            opacity: 1;
        }
    }

    .MuiFormControlLabel-labelPlacementStart {
        margin-left: 0;
        padding-top: 20px;
    }

    .MuiFormControl-root {
        display: flex;
    }

    .MuiFormGroup-row {
        flex-direction: column;
    }

    .MuiAlert-filledSuccess {
        background-color: $highlight-primary;
        width: 520px;
    }
}

.MuiDialog-root {
    .dialog-content {
        padding-top: 30px !important;

        .MuiDialogTitle-root {
            padding-left: 0 !important;
            color: $base-blue-100 !important;
        }

        .MuiDialogContentText-root {
            margin-bottom: 0 !important;
            color: $base-blue-100 !important;
        }
    }

    .MuiDialogActions-root {
        padding: 8px 24px 30px 24px;
        justify-content: space-around;

        button {
            margin-top: 20px;
            padding: 15px;
            min-width: 180px;

            .MuiButton-label {
                font-size: 18px;
                line-height: 20px;
            }
        }
    }

    .MuiPaper-rounded {
        border-radius: 0;
    }
}

.input-form {
    width: 100%;
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    > p {
        color: $base-blue-400;
    }

    .input-valid {
        fill: $highlight-primary;
    }

    .MuiInputBase-root {
        color: $base-blue-100;
        border-radius: 0;
    }

    .MuiSelect-icon {
        fill: $base-blue-100;
    }

    &.salutation {
        width: calc(50% - 10px);
        padding-right: 10px;
        padding-left: 0;
    }

    &.title {
        width: calc(50% - 10px);
        padding-left: 10px;
        padding-right: 0;
    }
}

.MuiFormHelperText-contained {
    margin-left: 0;
}

.MuiOutlinedInput-input {
    //padding-left: 0;
}

.underlined-input {
    text-align: left;
    //border-bottom: 2px solid $highlight-primary !important;
}

.disabled-input {
    //border-bottom: 2px solid $base-blue-400;
    text-align: left;

    &:focus-within {
        //border-bottom: 2px solid black;
    }
}

.customer-card {
    padding-top: 40px !important;
}

@media only screen and (max-width: $mobile-breakpoint) {
    .customer-card {
        padding-top: 20px !important;
        width: 90%;
    }
}

@media only screen and (min-width: $breakpoint + 1) {
    .user-dashboard-main-container {
        .MuiButton-root.mobile-icon {
            opacity: 0;
            cursor: auto;
        }
    }
}

@media only screen and (min-width: $tablet-breakpoint + 1) and (max-width: $breakpoint) {

        .padded-container {
            padding: 0 40px;
        }

        .asv-form-container {
            max-width: 90%;
            margin: auto;
        }

        .MuiButton-root.mobile-icon {
            opacity: 0;
            cursor: auto;
        }

}

@media only screen and (max-width: $tablet-breakpoint) {

        .padded-container {
            padding: 0 30px;
        }

        body .page-header {
            font-size: 20px;
            line-height: 37px;
            padding: 9px 0;
            background: $header-gradient !important;
            box-shadow: 0 0.5px 0 rgba(0, 0, 0, 0.3);
            backdrop-filter: blur(20px);
            color: $white;
            margin-bottom: 32px;
            min-height: 48px;
            border-bottom: transparent;

            .MuiSvgIcon-root {
                fill: $white;
            }

            &.scroll-border {
                border-bottom: none;
            }
        }

}

@media only screen and (max-width: $mobile-breakpoint) {

    .padded-container {
        padding: 32px 20px;
    }

    .blue-button, .green-button, .default-button {
        padding: 10px 16px;
        margin-top: 30px;
        max-width: 327px;
    }

    .outline-button {
        padding: 10px 50px;
        margin-top: 15px;
        max-width: 327px;
    }

    body .page-header {
        margin-bottom: 0;

        .MuiSvgIcon-root {
            fill: $white;
        }
    }

    .alert-message {
        padding: 20px 15px;

        p {
            padding-left: 15px;
            font-size: 15px;
            text-align: left;
        }
    }

    .MuiDialog-root {
        .MuiDialogContent-root {
            //padding: 15px !important;
        }

        .MuiDialogActions-root {
            padding: 15px;
            flex-direction: column;

            .green-button, .default-button {
                .MuiButton-label {
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            &.MuiDialogActions-spacing > :first-child {
                margin-top: 0;
            }

            &.MuiDialogActions-spacing > :not(:first-child) {
                margin-left: 0;
                margin-top: 10px
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    .user-dashboard-main-container {
        .blue-button, .green-button, .default-button, .outline-button {
            max-width: 300px;
        }
    }
}
