.plan-visit {
    margin: 0 auto;
}

.plan-title {
    font-size: 18px;
    padding: 30px 40px;
}

.plan-visit-title {
    font-size: 20px;
    color: #101c47;
    text-align: left;
    padding-left: 20px;
    padding-top: 20px;
    font-family: $font-aalto-bold;
}

.plan-visit-img {
    width: 100%;
}

.plan-visit-text {
    padding-bottom: 10px;
    padding-left: 20px;
    color: #505b7f;
    text-align: left;
    display: block;
    padding-right: 5%;
    width: 30%;
}

.plan-visit-text-date {
    padding-bottom: 10px;
    padding-left: 20px;
    color: #505b7f;
    text-align: left;
    width: 100%;
    margin: 0;
    font-size: 15px;
}

.line {
    border-bottom: 1px solid rgba($input-grey, .5);
    padding: 20px;
    margin: 0 20px;
}

.plan-visit-logo {
    position: relative;
    background-color: #f7f1ef;
    margin-top: 40px;
    padding: 16px;
}

.date {
    font-family: $font-aalto-bold;
}

@media only screen and (max-width: $mobile-breakpoint) {
    .plan-visit {
        .padded-container {
            padding: 0 !important;
        }
    }
}
