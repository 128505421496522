@import 'styles/constants';

#user_dashboard_root .image-container img {
    margin: 0 auto;
}

.single-box-details-container {

    padding-bottom:48px;

    .box-settings-navigation.MuiButton-root {
        line-height: 1;
    }

    .container-blue {
        text-align: center;
    }

    .shared-access {
        justify-content: center;
        padding-top: 30px;
    }

    .notification {
        border-radius: 5px;
    }

    .box-name {
        font-family: $font-aalto-semibold;
        margin: 0;
        font-size: 26px;
        line-height: 33px;
    }

    .box-nr {
        color: $input-grey;
        margin-top: 0;
        margin-bottom: 28px;
        font-size: 20px;
        line-height: 25px;
    }

    .box-info {
        display: flex;
        flex-direction: column;
        margin-top: 70px;
        color: $white;

        .box-info-row {
            border-bottom: 1px solid rgba($white, .5);
            padding: 10px 0;
            display: flex;
            align-items: center;

            &:last-child {
                border-bottom: none;
            }

            span {
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                width: calc(80% - #{$box-padding});
                text-align: left;
                color: $white;
                padding-left:8px;

                .MuiSvgIcon-root {
                    cursor: pointer;
                }
            }

            .valueContainer {
                flex-direction: column;
            }

            span:first-child {
                width: 20%;
                font-weight: normal;
                padding-right: $box-padding;
                white-space: nowrap;
            }
        }
    }

    .green-button {
        img {
            padding-left: 8px;
        }
    }
}

.box-insurance-section {

    text-align: left;


    .box-insurance-title{
        font-size: 20px;
    }

    .current-insurance-value {
        font-size: 20px;
        padding-bottom: 16px;
    }

    .small-print {
        margin-top: -16px;
        color: $base-blue-350;
    }


    .box-insurance-input {
        width: 100%;
        padding-bottom: 12px;
        margin-top: 8px;

        > p, #select-label {
            color: $base-blue-400;
            align-self: center;
        }

        .underlined-input {
            border-bottom: 2px solid $base-blue-400;
            text-align: left;
        }

        .MuiInputBase-root {
            border-radius: 0;
            //font-size: 20px;
            //line-height: 1;
        }

        .MuiSelect-icon {
            fill: $base-blue-100;
        }

        /*
        .MuiInputLabel-formControl {
            top: -10px;
        }

        .MuiOutlinedInput-input {
            padding-left: 0;
        }

        .MuiInputLabel-outlined.MuiInputLabel-shrink {
            transform: translate(0, -6px) scale(0.75);
        }

        .MuiInputLabel-outlined {
            transform: translate(0, 20px) scale(1);
        }

        .MuiOutlinedInput-multiline {
            padding: 18.5px 0;
        }*/
    }

    .price-container {
        font-family: $font-aalto-bold;
        font-size: 20px;
        border-top: 1px solid $base_blue_450;
        border-bottom: 1px solid $base_blue_450;
        margin-top: 32px;
        padding: 32px 0;

        .price-row {
            display: flex;
            justify-content: space-between;
            margin-top: 8px;

            span {
                font-family: $font-aalto-medium;
                font-size: 17px;
            }

            .label {
                font-family: $font-aalto-medium;
                font-size: 17px;
            }

            .price-left {
                flex: 2;
                padding-right: 32px;
            }

            .subline {
                font-family: $font-aalto-regular;
                font-size: 14px;
                color: $base-blue-300;
            }
        }
    }

    .status-icon-container {
        margin-top: 24px;
        margin-bottom: 48px;
    }
}

.box-contract-section {
    .outline-button {
        max-width: 520px;
    }

    .card-body-contract {
        margin: 32px auto 16px auto;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 48px 24px;
        background-color: $base-blue-500;

        .box-info {
            text-align: left;
            align-self: center;
            font-size: 20px;
            line-height: 25px;
            padding-left: 20px;

            .box-info-row {
                padding-bottom: 5px;
            }
        }
    }
}

.box-settings-container {
    .input-title {
        text-align: left;
    }

    .change-name-input {
        position: relative;
        background-color: $beige;
        width: 100%;
        padding: 10px;
        margin-top: 10px;
    }

    .link {
        position: absolute;
        right: 24px;
        top: 21px;
        color: $link-blue;
    }

    .counter {
        text-align: left;
    }
}

.locked-secure-info {
    padding: 30px 30px 50px 30px;

    .box-secure-description {
        color: $base-blue-100;
        text-align: left;
        font-size: 15px;
        padding-top: 20px;
    }

    .box-secure-row {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        padding: 30px 0;
        overflow-wrap: break-word;
        border-bottom: 1px solid $base-blue-400;
        text-align: left;

        .box-secure-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            color: $base-blue-300;

            .box-secure-title {
                font-family: $font-aalto-semibold;
                color: $base-blue-200;
            }
        }

        .box-secure-img {
            width: 80px;
            height: 80px;
            padding-right: 20px;
        }
    }

    .box-secure-row:last-child {
        border-bottom: none;
    }
}

.box-history {
    text-align: left;

    .box-description {
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .visits {
        display: flex;
        flex-direction: column;

        .visit {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid $base-blue-400;
            padding: 10px 0;

            &:last-child {
                border-bottom: none;
            }

            span {
                padding-right: 10px;
            }

            .visit-tag-sie, .visit-tag-partner {
                padding: 5px 10px;
                border-radius: 5px;
                font-family: $font-aalto-semibold;
            }

            .visit-tag-sie {
                background-color: $confirmation-green;
            }

            .visit-tag-partner {
                background-color: $base-blue-400;
            }
        }
    }
}

@media only screen and (min-width: $tablet-breakpoint + 1) and (max-width: $breakpoint) {
    .single-box-details-container {
        .box-info {
            padding: 0;
            margin-top: 40px;

            .box-info-row {
                span {
                    width: 65%;
                }

                span:first-child {
                    width: 35%;
                }
            }
        }
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    .single-box-details-container {
        .box-info {
            margin-top: 40px;

            .box-info-row {
                span {
                    width: 65%;
                }

                span:first-child {
                    width: 35%;
                }
            }
        }

        .image-container img {
            width: auto;
            height: 200px;
        }
    }

    .box-contract-section {
        padding-bottom: 40px;

        .card-body-contract {
            .box-info {
                padding-left: 30px;
            }
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .single-box-details-container {
        .container-blue {
            border-radius: 0;
            padding: 20px;
        }

        .box-name {
            font-size: 20px;
            line-height: 37px;
            word-break: break-all;
        }

        .notification {
            border-radius: 0;
        }

        .shared-access {
            justify-content: center;
        }

        .box-nr {
            font-size: 16px;
        }

        .box-info {
            margin-top: 20px;

            .box-info-row {
                span {
                    width: 70%;
                    font-size: 16px;
                }

                span:first-child {
                    width: 30%;
                }
            }
        }
    }

    .box-contract-section {
        .card-body-contract {
            margin: 0 auto 16px auto;
            padding: 40px 10px;

            .box-info {
                padding-left: 30px;
                font-size: 16px;
                line-height: 16px;
            }

            .image-container {
                .small-image {
                    width: 100px;
                    height: auto;
                }
            }
        }
    }

    .box-settings-container {
        .change-name-input {
            width: 93%;
        }
    }

}

@media only screen and (max-width: $breakpoint-xs) {
    .box-contract-section {
        .card-body-contract {
            padding: 20px 10px;

            .box-info {
                font-size: 15px;
                padding-left: 10px;
            }

            .image-container {
                .small-image {
                    width: 80px;
                    height: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: $breakpoint-xs-interim) {
    .single-box-details-container {
        .box-info {
            padding: 0 5px;
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .single-box-details-container {
        .padded-container {
            padding: 0px;
        }
    }
}
