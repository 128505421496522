@import '../../styles/constants';

.user-data-container {
    color: $base-blue-100;

    .title {
        font-family: $font-aalto-semibold;
        font-size: 20px;
        line-height: 25px;
    }

    .address-title {
        display: flex;
        justify-content: space-between;
    }

    .address-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 17px;

        p {
            margin: 0;
        }
    }

    .section {
        display: flex;
        flex-direction: column;
        padding-top: 40px;

        .title {
            align-self: flex-start;
        }
    }

    .email, .phone {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid $base-blue-400;
        font-size:17px;

        p {
            color: $base-blue-400;
            font-family: $font-aalto-medium;
        }

        .info-detail {
            display: flex;
            margin-bottom: -10px;

            .retrieved-email{
                padding-left: 20px;
                color: $base-blue-100;
            }
            .retrieved-phone{
                padding-left: 13px;
                color: $base-blue-100;
            }
        }
    }

    .phone {
        margin-bottom: 10px;
    }

    .chevron-right {
        cursor: pointer;
        align-self: center;
        color: $base-blue-400;
    }

    .card-section {
        .grey-section {
            display: flex;
            justify-content: space-between;
            background-color: $beige;
            padding: 20px;
            margin-top: 10px;

            .payment-logo {
                display: flex;
                align-items: center;

                p {
                    padding-left: 5px;
                    color: black;
                }
            }

            .card-link {
                align-self: center;
            }

            a {
                color: $link-blue;
            }
        }

        .error-message {
            text-align:left;
            padding-top:4px;
            color:$red;
        }

        .payment-input {
            background:$beige;
            margin-top:10px;
        }

        .braintree-container {
            padding:24px;
        }

        .button-container {
            display: flex;
            flex-direction: row;
            gap:16px;
        }
    }

    .privacy-policy {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 50px;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        width: 100%;
    }
}

.user-form-container {
    .padded-container {
        padding-top: 20px;
    }

    .user-data-input-container {
        width: 100%;
        padding-bottom: 12px;

        > p {
            color: $base-blue-400;
        }

        .input-valid {
            fill: $highlight-primary;
        }
    }

    .MuiFormHelperText-contained {
        margin-left: 0;
    }

    .MuiOutlinedInput-input {
        //padding-left: 0;
    }

    .MuiFormHelperText-root {
        color: $beige;
    }

    .underlined-input {
        //border-bottom: 2px solid $highlight-primary;
        text-align: left;
    }

    .disabled-input {
        //border-bottom: 2px solid $base-blue-400;
        text-align: left;

        &:focus-within {
            //border-bottom: 2px solid black;
        }
    }

    .password-confirmation-text {
        .add-password {
            text-align: left !important;
            padding: 20px 0;
            font-size: 20px;
            line-height: 1;
        }

        .password-form {
            padding-top: 10px;
        }
    }

    .MuiInputBase-root {
        color: $base-blue-100;
        border-radius: 0;
        font-size: 20px;
        //line-height: 1;
    }

    .MuiSelect-icon {
        fill: $base-blue-100;
    }

    .salutation {
        width: calc(50% - 10px);
        padding-right: 10px;
        padding-left: 0;
    }

    .title {
        width: calc(50% - 10px);
        padding-left: 10px;
        padding-right: 0;

    }

    .street {
        width: calc(70% - 10px);
        padding-right: 10px;
        padding-left: 0;
    }

    .street-number {
        width: calc(30% - 10px);
        padding-right: 0;
        padding-left: 10px;
    }

    .postcode {
        width: calc(30% - 10px);
        padding-left: 0;
        padding-right: 10px;
    }

    .city {
        width: calc(70% - 10px);
        padding-right: 0;
        padding-left: 10px;
    }
}

.dialog-underline {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: $highlight-primary;
    font-family: $font-aalto-semibold;
    font-size: 20px;
    line-height: 25px;
}

.add-card-section {
    .container-blue {
        margin-bottom: 40px;
        //border-radius: 0;
    }

    .register-card-description {
        text-align: left;
        font-size: 18px;
    }

    .blue-button {
        margin-bottom: 20px;
    }

    p.registered-card-title {
        text-align: left;
        font-family: $font-aalto-medium;
        font-size: 20px;
        line-height: 1;
        margin: 0;
        padding: 10px 0;
    }

    .registered-card-number {
        display: flex;
        justify-content: center;
        background-color: $beige;
        padding: 10px 0;
        font-size: 20px;
        line-height: 1;
        position: relative;

        .MuiOutlinedInput-notchedOutline {
            border: 0 !important;
        }

        .MuiInput-underline {
            justify-content: center;
        }

        .MuiInput-underline.Mui-disabled:before {
            border-bottom: none;
        }

        .MuiInputBase-root.Mui-disabled {
            color: $base-blue-100;
            font-family: $font-aalto-semibold;
            font-size: 20px;
            line-height: 1;

            input {
                text-align: center
            }
        }

        .checkmark {
            fill: $confirmation-green;
            align-self: center;
            position: absolute;
            right: 20px;
        }
    }
}

@media only screen and (min-width: $breakpoint) {
    .user-data-container {
        .page-header {
            justify-content: center;
        }
    }

    .user-form-container {
        .first-name {
            width: calc(50% - 10px);
            padding-right: 10px;
            padding-left: 0;
        }

        .last-name {
            width: calc(50% - 10px);
            padding-left: 10px;
            padding-right: 0;
        }
    }
}

@media only screen and (max-width: $breakpoint) {
    .user-form-container {
        .title label {
            transform: translate(22px, 39px) scale(1);
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .user-form-container {
        .MuiInputBase-root, .add-password {
            font-size: 18px !important;
        }
    }

    .email, .phone {
        p {
            font-size: 16px;
        }
    }

    .user-data-container {

        .outline-button {
            max-width: 100% !important;
        }

        .payment-logo p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 140px;
            text-align: left;
        }

        .MuiSvgIcon-root {
            width: 18px;
            height: 18px;
        }
    }

    .add-card-section {
        .register-card-description {
            padding: 0 20px;
            text-align: justify;
            font-size: 16px;
        }

        .overlay {
            padding: 0 !important;
        }

        .container-blue {
            padding: 0 !important;

            img {
                max-width: 300px;
            }
        }

        .registered-card-title {
            padding: 10px 20px 5px !important;
            font-size: 18px !important;
        }

        .registered-card-number {
            margin: 0 20px 20px;

        }
    }
}

@media only screen and (max-width: $breakpoint-xs-interim) {
    .user-data-container {
        .info-detail p {
            overflow-wrap: anywhere;
        }
    }
}
