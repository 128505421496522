.notification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-bottom: 24px;
    font-family: $font-aalto-medium;
    padding: 24px;
    font-size: 17px;
    line-height: 22px;
    background-color: $highlight-primary;

    &.clickable {
        cursor: pointer;
    }

    &.is-post-ident {
        background-color: $alert-orange;
    }

    &.is-warning {
        color: $warning-dark;
        background-color: $pink;
    }

    .MuiSvgIcon-root {
        width: 24px;
        height: 24px;
    }

    .notification-navigate {
        cursor: pointer;
    }
}


@media only screen and (max-width: $mobile-breakpoint) {

    .notification {
        margin: 0;
        font-size: 14px;
        line-height: 19px;
        padding: 15px;
    }
}
