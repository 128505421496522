.access-request-container {
    .description-unregistered, .description-registered {
        text-align: left;
        font-size: 20px;
        line-height: 1;
    }

    .code-input {
        background-color: $beige;
        width: 100%;
        padding: 10px 0;
        margin: 20px 0 !important;

        .input-code-format {
            font-size: 20px;
            font-family: inherit;
            color: rgba(0, 0, 0, 0.87);
            border: none;
            outline: none;
            background-color: transparent;
            padding: 8px 14px;
        }

        .input-code-format::placeholder {
            color: rgba(0, 0, 0, 0.4) !important;
        }
    }

    .MuiInputBase-root {
        font-size: 20px;
    }

    .text-success {
        padding-top: 40px;
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .access-request-container {
        .description-unregistered, .description-registered, .MuiInputBase-root {
            font-size: 18px !important;
        }
    }
}
