.report-loss {
    .title, .description {
        text-align: left;
    }

    .title {
        font-family: $font-aalto-semibold;
    }

    .blue-button {
        margin-bottom: 40px;
    }
}

.dialog-content {

    .dialog-title {
        color: $base-blue-100 !important;
        padding-bottom: 10px;
        font-family: $font-aalto-bold;
    }

    .report-loss-box-list-item {
        display: block;
        border-bottom: 1px solid $base-blue-400;
        text-decoration: none;
        padding: 16px 0px;
        //margin: 20px 0;
    }

    .report-loss-box-list-item:last-child {
        border-bottom: none;
    }
}

@media only screen and (min-width: $breakpoint) {
    .report-loss {
        .page-header {
            justify-content: center;
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .report-loss {
        .title, .description {
            font-size: 18px;
        }
    }
}
