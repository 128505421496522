.configure-shared-access-container {
    .blue-button {
        margin: 40px auto;
    }

    .container-blue {
        border-radius: 0;
        text-align: center;

        img {
            padding: 100px 0;
        }
    }

    .text-content {
        text-align: left;
        padding: 20px 40px;
    }

    .partner-container {
        display: flex;
        justify-content: space-between;
        background-color: $beige;
        padding: 15px 10px;
        color: black;
        margin-top: 15px;
    }

    .partner-container {
        align-items: center;
        text-align: left;

        p {
            margin: 0;
        }

        .partner-info {
            color: $base-blue-100;
            display: flex;
            flex-direction: column;
            padding: 10px;

            .name {
                padding-bottom: 5px;
            }
        }

        .checked-step {
            color: $highlight-primary;
        }
    }

    label {
        display: flex !important;
        justify-content: space-between !important;

        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
            background-color: $highlight-primary;
            opacity: 1;
        }
    }

    .MuiFormControlLabel-labelPlacementStart {
        margin-left: 0;
        padding-top: 20px;
    }

    .MuiFormControl-root {
        display: flex;
    }

    .MuiFormGroup-row {
        flex-direction: column;
    }

    .MuiAlert-filledSuccess {
        background-color: $highlight-primary;
        width: 520px;
    }

    .Mui-disabled .MuiSwitch-root {
        opacity: .3;
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .configure-shared-access-container {
        .overlay {
            padding: 0 !important;
        }

        .container-blue {
            padding: 0 !important;
            border-radius: 0;
        }

        .text-content {
            padding: 20px;
        }
    }
}
